import { BrowserModule } from '@angular/platform-browser';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { AuthServiseService } from './services/auth-servise.service';
import {  NotificationService} from "./services/notification.service";
import { ProviderGuardService,AdminGuardService } from "./services/auth-guard.service";
import { HttpCallService } from "./services/http-call.service";
import {HttpClientModule} from '@angular/common/http';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NavbarComponent } from './navbar/navbar.component';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {ProgressBarModule} from 'primeng/progressbar';
import {InputTextModule} from 'primeng/inputtext';

import { NgxSpinnerModule } from "ngx-spinner";
import { AgmCoreModule } from '@agm/core';
import { SharedModuleModule } from './shared-module/shared-module.module';
import { CmsFileInsertionComponent } from './cms-insertion/cms-file-insertion/cms-file-insertion.component';
import { CmsScrappingInsertionComponent } from './cms-insertion/cms-scrapping-insertion/cms-scrapping-insertion.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    NavbarComponent
    ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TableModule,
    CalendarModule,
		SliderModule,
		DialogModule,
		MultiSelectModule,
		ContextMenuModule,
		DropdownModule,
		ButtonModule,
		ToastModule,
    InputTextModule,
    ProgressBarModule,
    NgxSpinnerModule,
    SharedModuleModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDXyNL-7HgvYrvpocu3mC5VLbUpkjrOMi8',
      libraries: ["places"]
    }),
  ],
  providers: [AuthServiseService,NotificationService, ProviderGuardService, AdminGuardService, 
    HttpCallService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
