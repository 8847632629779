
<style>
  body {
    font-family: "Lato", sans-serif;
  }
  
  .sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }
  
  .sidebar a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 18px;
    color: #FFF;
    display: block;
    transition: 0.3s;
  }
  
  .sidebar a:hover {
    color: #f1f1f1;
  }
  
  .sidebar .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  
  .openbtn {
    font-size: 20px;
    cursor: pointer;
    background-color: inherit;
    color: white;
    padding: 10px 15px;
    border: none;
  }
  .openbtn:focus{
    outline: none !important;
    border: none !important;
  }
  
  
  .dropdown-container {
    display: none;
    background-color: #262626;
    padding-left: 8px;
  }
  .active {
    /* background-color: green; */
    color: white;
  }
  .sidenav a:hover, .dropdown-btn:hover {
    color: #f1f1f1;
  }
  .sidenav a, .dropdown-btn {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 18px;
  color: #FFF;
  display: block;
  border: none;
  background: none;
  width: 100%;
  cursor: pointer;
  outline: none;
}
  #main {
    transition: margin-left .5s;
    padding: 0px;
  }
  
  /* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
  @media screen and (max-height: 450px) {
    .sidebar {padding-top: 15px;}
    .sidebar a {font-size: 18px;}
  }
  </style>
<div id="mySidebar" class="sidebar">
  <a href="javascript:void(0)" class="closebtn"(click)="closeNav()" >&times;</a>
  <a [routerLink]="['/dashboard']">Dashboard</a>
  <a [routerLink]="['provider']"  routerLinkActive="active">Manage Provider</a>
  <button class="dropdown-btn" (click)="showMenu(1)">
    Manage Subscriptions <i class="fa fa-caret-down"></i>
  </button>
  <div class="dropdown-container" id="dropdown-container" >
    <a [routerLink]="['subscriptions/features']"  routerLinkActive="active">Features</a>
    <a [routerLink]="['subscriptions/addons']"  routerLinkActive="active">Addons</a>
    <a [routerLink]="['subscriptions']"  routerLinkActive="active">Plans</a>
  </div>
  <a [routerLink]="['manageAppointments']" routerLinkActive="active">Manage Orders</a>
  <a [routerLink]="['manageUsers']">Manage Users</a>
  <a [routerLink]="['masterTests']" routerLinkActive="active">Master Tests</a>
  <a [routerLink]="['reconciliation']" routerLinkActive="active">Reconciliation</a>
  <a [routerLink]="['support']" routerLinkActive="active">Support</a>
  <a [routerLink]="['faqs']" routerLinkActive="active"> FAQ's </a>
  <a [routerLink]="['insights']" routerLinkActive="active">Insights</a>
  <a [routerLink]="['dataSource']" routerLinkActive="active">Data Management</a>
  <a [routerLink]="['cms']" routerLinkActive="active">CMS</a>
  <a [routerLink]="['cms/assign-owners']" routerLinkActive="active">Data Insertion</a>
  
  <a [routerLink]="['marketing']" routerLinkActive="active">Marketing Tools</a>
  <a [routerLink]="['pledges']" routerLinkActive="active">ZeaPledges</a>
  <a [routerLink]="['zeacards/templates']" routerLinkActive="active">Manage Templates</a>
  <a [routerLink]="['zeacards/inventory/sales-order-config']" routerLinkActive="active">Manage Deals</a>
  <!-- <a href="#">Contact</a> -->
</div>

<div id="main">
  <header class="header bg-custom-primary">
    <div class="row">
      <button class="openbtn" (click)="openNav()" >&#9776;</button>
      <p class="welcome ml-2 p-1 mt-3">Welcome, {{userName}}</p>
      <span (click)="Logout()" style="margin-left: 70%;cursor: pointer;" class="p-1 mt-3">Logout <i class="pi pi-sign-out"></i> </span>
    </div>
  </header>

  <router-outlet></router-outlet>

</div>