import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpCallService } from '../services/http-call.service';
import {NgxSpinnerService} from 'ngx-spinner';
import { environment } from 'src/environments/environment.prod';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '../services/notification.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  postNavigation: any;

  constructor(public http:HttpCallService,public spinner:NgxSpinnerService,public router:Router,public notification:NotificationService,public route:ActivatedRoute) {
    this.postNavigation = this.route.snapshot.queryParams.redirectUrl;

  }
  item:any = {
    email:'',
    password:''
  }
  ngOnInit(): void {

  }

  ValidateEmail(mail){
      if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail))
      {
        return (true)
      }
      return (false)
  }
  LoginAction(Login:NgForm){
    if(this.ValidateEmail(this.item.email) == false){
      Login.form.setErrors({
        message:"Invalid email."
      });
      alert("Invalid email")
    }else{

      this.spinner.show();
      this.http.post(`${environment.zeatool_be}/BackEnd/adminlogin`,this.item).subscribe(res=>{
          if(res['success']){
            this.spinner.hide();
            this.notification.showNotification("success",res['message'])
            localStorage.clear();
            localStorage.setItem('token',res['response'].token);
            localStorage.setItem('email',res['response'].user);
            localStorage.setItem('id',res['response'].id);
            localStorage.setItem('lname',res['response'].lname);
            localStorage.setItem('fname',res['response'].fname);
            localStorage.setItem('role','0');
  
            if(this.postNavigation !=null || this.postNavigation != undefined){
              console.log(this.postNavigation);
              if(this.postNavigation === `${'/'}`){
                this.router.navigate(['dashboard/cms/table'])
              }
              this.router.navigate([`${this.postNavigation}`]);
            }else{
              this.router.navigate(['/dashboard/cms/table']);
            }
          }else{
            this.spinner.hide();
              this.notification.showNotification("error",res['message'])
              this.router.navigate(['/']);
          }
      },err=>{
        this.spinner.hide();
        this.router.navigate(['/']);
  
      });
  
    }
  }
}
