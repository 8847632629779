<div class="main-overview">
  <div class="p-d-flex">
      <div class="p-col-3 p-mr-1 card">
        1
      </div>
      <div class="p-col-3 p-mr-1 card">2</div>
      <div class="p-col-3 p-mr-1 card">3</div>
      <div class="p-col-3 p-mr-1 card">4</div>
  </div>
  <div class="p-d-flex mt-2">
      <div class="p-col-6">
        <app-basic-chart [id]="'chartOne'" [data]="areaOptions"></app-basic-chart>
      </div>
      <div class="p-col-6">
        <app-basic-chart [id]="'chartTwo'" [chartType]="'pie'" [data]="pieChartOptions"></app-basic-chart>
      </div>
  </div>

  <div class="p-d-flex mt-2">
    <div class="p-col-6">
      <app-basic-chart [id]="'chart2'" [chartType]="'column'" [data]="columnOptions"></app-basic-chart>
    </div>
    <div class="p-col-6">
      <app-basic-chart [id]="'chart3'" [chartType]="'pie'" [data]="pieChartOptions"></app-basic-chart>
    </div>
</div>
</div>
