import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {SidebarModule} from 'primeng/sidebar';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  showMyMenu:Number = 0;
  userName:string = localStorage.getItem('fname')
  constructor(public router:Router) { }
  ngOnInit(): void {
    this.openNav();
  }

  openNav() {
    document.getElementById("mySidebar").style.width = "250px";
    document.getElementById("main").style.marginLeft = "250px";
  }

  closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("main").style.marginLeft= "0";
  }


  Logout(){
    localStorage.clear();
    this.router.navigate(['/'])
  }


  poweredDate = new Date();
  showMenu(number:Number){
    console.log(this.showMyMenu);
    switch (number) {
      case 1:
        if(this.showMyMenu != 0) {
          this.showMyMenu = 0;
          document.getElementById('dropdown-container').style.display = "none";
        }else{
          document.getElementById('dropdown-container').style.display = "block";
          this.showMyMenu = number;
        }
        break;
    
      default:
        break;
    }
  }
}
