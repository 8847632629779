import { Injectable } from '@angular/core';
import { RequestServiceService } from './request-service.service';
import { Router, ActivatedRoute } from '@angular/router';


@Injectable({

  providedIn: 'root',

})
export class AuthServiseService {

  constructor(public service:RequestServiceService,public router:Router,public route:ActivatedRoute) { }
  canActivate() {
    // console.log(this.router.url);
   if(localStorage.getItem('token')) {
    //  this.service.getProfile().subscribe(res => {
    //    if(res['success']) {
    //      return true;
    //    }
    //    else {
    //     console.log("Getting here boi",)

    //      localStorage.clear();
    //      this.router.navigate(['/'],{queryParams:{redirectUrl:this.router.url}});
    //      return false;
    //    }
    //  }, (err) => {
    //    localStorage.clear();
    //    this.router.navigate(['/'],{queryParams:{redirectUrl:this.router.url}});
    //   });
     return true;
   } else {
     localStorage.clear();
     this.router.navigate(['/'],{queryParams:{redirectUrl:this.router.url}});
     return false;
   }
 }

}
