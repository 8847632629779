import { Injectable } from "@angular/core";
import Swal from 'sweetalert2';

@Injectable()
export class NotificationService {
  // showNotification(messageType, message) {
  //   const toast = (Swal as any).mixin({
  //     toast: true,
  //     position: 'top',
  //     showConfirmButton: false,
  //     timer: 3000
  //   });
    
  //   toast({
  //     type: messageType,
  //     title: message
  //   });
  // }
  showNotification(messageType, message) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    
    Toast.fire({
      icon: messageType,
      title: message
    })
  }
}