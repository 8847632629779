<div class="container mt-4">
    <div class="col-xs col-sm-4 col-md-4 col-lg-6 centerMargin">
        <div class="card" style="width: 25rem;">
            <div class="card-body">
              <form #Login="ngForm" (ngSubmit)="LoginAction(Login)"> 
                <h2 class="text-center">Login</h2>
                <div class="form-group">
                    <input type="email" name="email" class="form-control" placeholder="email" required [(ngModel)]="item.email">
                </div>
                <div class="form-group">
                    <input type="password" name="password" class="form-control" placeholder="password" required [(ngModel)]="item.password">
                </div>
                <span class="container">
                    <button type="submit"class="btn btn-block btn-success btn-login" [disabled]="!Login.form.valid">Login</button>
                </span>
              </form>
            </div>
        </div>
    </div>
</div>