import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class RequestServiceService {

  constructor(public http:HttpClient) { }

  getProfile(): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders({
      'authorization': token
    });
    let options = {
      headers: headers
    }
    return this.http.get(`${environment.superAdminDomain}/api/profile`, options);
  }

  getHeaders(url){
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders({
      'authorization': token
    });
    let options = {
      headers: headers
    }
    return this.http.get(url,options);
  }

}
