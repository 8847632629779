import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GooglePlacesDirective } from '../google-places.directive';
import { BasicChartComponent } from './basic-chart/basic-chart.component';
import { NumberSuffixPipe } from '../services/number-suffix.pipe';
import { ZeacardPreviewComponent } from './zeacard-preview/zeacard-preview.component';

@NgModule({
  declarations: [GooglePlacesDirective, BasicChartComponent, NumberSuffixPipe, ZeacardPreviewComponent],
  imports: [
    CommonModule,
  ],
  exports:[
    GooglePlacesDirective,
    BasicChartComponent,
    NumberSuffixPipe,
    ZeacardPreviewComponent
  ]
})
export class SharedModuleModule { }
