import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpCallService } from 'src/app/services/http-call.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-zeacard-preview',
  templateUrl: './zeacard-preview.component.html',
  styleUrls: ['./zeacard-preview.component.scss']
})
export class ZeacardPreviewComponent implements OnInit {

  @Input() previewCardObj: any;

  constructor(
    public httpCall: HttpCallService,
    public notification: NotificationService,
    public spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.getThemeDetails(this.previewCardObj.themeID);
  }

  themeObj: any;
  getThemeDetails(themeID) {
    this.spinner.show();
    this.httpCall.get(`${environment.superAdminDomain1}/getSingletheme/${themeID}`).subscribe((res) => {
      this.spinner.hide();
      if(res['success']) {
        this.themeObj = res['response'];
        if(this.themeObj.front_url) {
          this.getImageFromUrl(this.themeObj.front_url, 'Front');
        }
        if(this.themeObj.back_url) {
          this.getImageFromUrl(this.themeObj.back_url, 'Back');
        }
      }
    }, err => {
      this.spinner.hide();
      this.notification.showNotification('error', err.error.message);
    });
  }

  frontImagePath: string;
  backImagePath: string;
  getImageFromUrl(url, type) {
    this.spinner.show()
    this.httpCall.get(`${environment.superAdminDomain1}/getSignedUrl/${url}/2`).subscribe((res)=> {
      this.spinner.hide();
      if(res['success']) {
        if(type == 'Front') {
          this.frontImagePath =  res['response'];
        } else if(type == 'Back') {
          this.backImagePath =  res['response'];
        } else {

        }
      }
    }, err => {
      this.spinner.hide();
      this.notification.showNotification('error', err.error['message']); 
    });
  }

}
