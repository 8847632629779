<div *ngIf="themeObj">
    <div class="mb-2 position-relative">
        <!-- <div class="position-absolute front-card">
            <p class="h2 text-white">{{previewCardObj.serviceName}}</p>
        </div> -->
        <div class="position-absolute price">
            <p class="h3 font-weight-bold">{{previewCardObj.cardPrice || 0000 | currency:'USD':'symbol':'1.0'}}</p>
        </div>
        <div class="position-absolute validity" *ngIf="previewCardObj?.validFrom">
            <p>{{previewCardObj?.validFrom | date}} - {{previewCardObj?.validTo | date}}</p>
        </div>
        <div class="position-absolute available" *ngIf="previewCardObj?.validIn">
            <p>{{previewCardObj?.validIn}}</p>
        </div>
        <ng-container *ngIf="themeObj.front_url; else defaultFront">
            <img [src]="frontImagePath" class="border rounded border-dark" width="490" height="300"/>
        </ng-container>
        <ng-template #defaultFront>
            <img src="assets/demo-card-front.png" class="border rounded border-dark" width="490" height="300"/>
        </ng-template>
    </div>
    <div class="mb-2 position-relative">
        <div class="position-absolute" style="top: 15px;left: 15px;">
            <img src="assets/zeacard-instructions-back.png" height="75"/>
        </div>
        <div class="position-absolute" style="top: 88px;left: 25px;">
            <img src="assets/qr-code-demo.png" height="110" width="110"/>
        </div>
        <div class="position-absolute card-number">
            <p class="h4">1234567890</p>
        </div>
        <div class="position-absolute include-price" *ngIf="previewCardObj.priceIncludes.length > 0">
            <p>
                <span>Price includes: </span>
                <span *ngFor="let item of previewCardObj.priceIncludes">{{item}}, </span>
            </p>
        </div>
        <ng-container *ngIf="themeObj.back_url; else defaultBack">
            <img [src]="backImagePath" class="border rounded border-dark" width="490" height="300"/>
        </ng-container>
        <ng-template #defaultBack>
            <img src="assets/demo-card-back.png" class="border rounded border-dark" width="490" height="300"/>
        </ng-template>
    </div>
</div>