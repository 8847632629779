import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NavbarComponent } from './navbar/navbar.component';
import { AuthServiseService } from './services/auth-servise.service';

const routes: Routes = [
  {
    path:'',
    component:LoginComponent
  },
  {
    path:'dashboard',
    component:NavbarComponent,
    children:[
      {
        path:'',
        component:DashboardComponent,
        canActivate:[AuthServiseService]
      },
      {
        path:'dataCollect/:providerNumber',
        loadChildren:()=> import('./compliacedata/compliacedata.module').then(m=>m.CompliacedataModule)
      },
      {
        path:'provider',
        loadChildren:()=> import('./manage-providers/manage-providers.module').then(m=>m.ManageProvidersModule)
      },
      {
        path:'subscriptions',
        loadChildren:()=> import('./master-subscriptions/master-subscriptions.module').then(m=>m.MasterSubscriptionsModule)
      },
      {
        path:'manageAppointments',
        loadChildren:()=> import('./manage-appointments/manage-appointments.module').then(m=>m.ManageAppointmentsModule)
      },
      {
        path:'manageUsers',
        loadChildren:()=> import('../app/manage-users/manage-users.module').then(m=>m.ManageUsersModule)
      },
      {
        path:'masterTests',
        loadChildren:()=> import('./manage-master-tests/manage-master-tests.module').then(m=>m.ManageMasterTestsModule).catch(err=> console.log(err))
      },
      {
        path:'reconciliation',
        loadChildren:()=> import('./reconciliation/reconciliation.module').then(m=>m.ReconciliationModule).catch(err=> console.log(err))
      },
      {
        path:'support',
        loadChildren:()=> import('./support-module/support-module.module').then(m=>m.SupportModuleModule).catch((err)=> console.log(err))
      },
      {
        path:'faqs',
        loadChildren:()=> import('./faqs/faqs.module').then(m=>m.FAQSModule).catch((err)=> console.log(err))
      },
      {
        path:'insights',
        loadChildren:()=> import('./insights/insights.module').then((m)=>m.InsightsModule)
      },
      {
        path:'dataSource',
        loadChildren:()=> import('./data-management/data-management.module').then(m=>m.DataManagementModule)
      },
      {
        path:'cms',
        loadChildren:()=> import('./cms-data/cms-data.module').then(m=>m.CmsDataModule).catch(err=> console.log(err))
      },
      {
        path:'marketing',
        loadChildren:()=>import('./marketing/marketing.module').then(m=>m.MarketingModule).catch(err=>console.log(err))
      },
      {
        path:'data-insertion',
        loadChildren:()=>import('./cms-insertion/cms-insertion.module').then(m=>m.CmsInsertionModule).catch(err=>console.log(err))
      },
      {
        path:'pledges',
        loadChildren:()=> import('./pledges/pledges.module').then(m=>m.PledgesModule).catch(err=> console.log(err))
      },
      {
        path: 'zeacards/templates',
        loadChildren:()=> import('./zea-cards/manage-templates/manage-templates.module').then(m=>m.ManageTemplatesModule).catch(err=> console.log(err))
      },
      {
        path: 'zeacards/inventory',
        loadChildren:()=> import('./zea-cards/cards-inventory/cards-inventory.module').then(m=>m.CardsInventoryModule).catch(err=> console.log(err))
      },
      {
        path: 'zeacards/sales-order-config',
        loadChildren:()=> import('./zea-cards/sales-order-config/sales-order-config.module').then(m=>m.SalesOrderConfigModule).catch(err=> console.log(err))
      },
      {
        path: 'zeacards/sales-order',
        loadChildren:()=> import('./zea-cards/sales-orders/sales-orders.module').then(m=>m.SalesOrdersModule).catch(err=> console.log(err))
      },
      {
        path: 'zeacards/purchase-order',
        loadChildren:()=> import('./zea-cards/purchase-orders/purchase-orders.module').then(m=>m.PurchaseOrdersModule).catch(err=> console.log(err))
      },
      {
        path: 'zeacards/cards-inventory',
        loadChildren:()=> import('./zea-cards/inventory/inventory.module').then(m=>m.InventoryModule).catch(err=> console.log(err))
      },
    ]
  },

  {
    path: 'zeacards',
    loadChildren:()=> import('./zea-cards/cards-inventory/cards-inventory.module').then(m=>m.CardsInventoryModule).catch(err=> console.log(err))
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,{useHash: true})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
